@import "./assets/scss/_config.scss";
.homepage-assortments {
  --bootstrap-gap-size: 0.9375rem;
}

.container.homepage-assortments {
  display: grid;
  grid-template: 1fr 32rem / 1fr;
  width: 100%;
  overflow: visible;
  margin-inline: 0;
  padding-inline: 0;
}

@media (min-width: 48rem) {
  .container.homepage-assortments {
    width: 45rem;
    margin-inline: auto;
  }
}

@media (min-width: 62rem) {
  .container.homepage-assortments {
    grid-template: auto / 3fr 1fr;
    gap: var(--bootstrap-gap-size);
    width: 60rem;
    margin-inline: auto;
  }
}

@media (min-width: 75rem) {
  .container.homepage-assortments {
    width: 71.25rem;
  }
}

@media (min-width: 87.5rem) {
  .container.homepage-assortments {
    width: 85rem;
  }
}

.homepage-assortments__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: max-content;
  align-content: start;
  gap: var(--bootstrap-gap-size);
  padding: 0 var(--bootstrap-gap-size) var(--bootstrap-gap-size);
}

@media (min-width: 48rem) {
  .homepage-assortments__grid {
    padding: 0 0 var(--bootstrap-gap-size);
  }
}

@media (min-width: 62rem) {
  .homepage-assortments__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
  }
}

.homepage-assortments__content {
  padding: 1rem;
  background: var(--background-light);
}

@media (min-width: 48rem) {
  .homepage-assortments__content {
    border-radius: var(--border-radius);
  }
}

.assortment-grid__item {
  padding: 0;
}

.assortment-grid__anchor {
  display: block;
  max-width: 100%;
}

.assortment-grid__anchor:any-link,
.assortment-grid__anchor:hover {
  text-decoration: none;
}

.assortment-grid__display {
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-rows: minmax(0, 10.75rem);
  max-height: 10.75rem;
  aspect-ratio: 325 / 172;
  background-color: var(--background-light);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.assortment-grid__image {
  width: 100%;
  min-width: 100%;
  height: 10.75rem;
  object-fit: contain;
}

@media (min-width: 48rem) {
  .assortment-grid__image {
    width: auto;
  }
}

@media (min-width: 62rem) {
  .assortment-grid {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: min-content;
    gap: 1rem;
    justify-items: start;
  }

  .assortment-grid__content {
    grid-column: 3 / 4;
  }

  .assortment-grid__image {
    width: auto;
    max-width: 100%;
    max-height: 10.75rem;
  }
}

.assortment-grid__heading {
  line-height: 1.125;
  padding-block: .75rem 0;
  text-align: center;
  font-size: 1.125rem;
}

.text-column {
  grid-area: 2 / 1 / 3 / 2;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  margin-bottom: 2.5rem;
}

.text-column:empty {
  display: none;
}

@media (min-width: 62rem) {
  .text-column {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.text-column--has-overflow {
  overflow: hidden;
}

.text-column.text-column--is-expanded {
  overflow: visible;
  display: flex;
  min-height: 100%;
}

.text-column__content {
  display: flex;
  min-height: 100%;
}

.text-column--is-expanded .text-column__content {
  display: grid;
  grid-template-columns: auto 3rem;
}

.text-column__overflow {
  position: absolute;
  z-index: 110;
  inset: 0 0 auto;
  height: max-content;
  width: 100%;
  padding: 1rem;
}

@media (min-width: 48rem) {
  .text-column__overflow {
    border-radius: var(--border-radius);
    padding: 1.5rem;
  }
}

@media (min-width: 62rem) {
  .text-column__overflow {
    padding: 1rem;
  }
}

@media (min-width: 75rem) {
  .text-column__overflow {
    padding: 1.5rem;
  }
}

.text-column--is-expanded .text-column__overflow {
  flex: 1;
  inset: 0;
  overflow-x: hidden;
  background-color: var(--background-light);
}

@media (min-width: 62rem) {
  .text-column--is-expanded .text-column__overflow {
    inset: 0;
  }
}

.text-column__resize--more {
  display: none;
}

.text-column__resize:focus {
  outline: none;
}

.text-column__resize:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-column--is-expanded .text-column__resize:before {
  display: none;
}

.text-column__resize {
  display: none;
  justify-content: start;
  align-items: center;
  z-index: 111;
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: 1rem;
  border: none;
  outline: none;
  color: var(--primary);
  background-color: var(--background-light);
  font-size: 1rem;
  font-weight: 600;
}

.text-column__resize:before {
  position: absolute;
  content: '';
  inset: -1.5rem 1rem 1rem;
  height: 1.5rem;
  background-image: linear-gradient(to bottom, transparent, var(--background-light));
}

@media (min-width: 48rem) {
  .text-column__resize {
    border-radius: var(--border-radius);
  }
}

@media (min-width: 75rem) {
  .text-column__resize {
    padding: 0 1.5rem;
  }
}

.text-column--has-overflow .text-column__resize--more {
  display: flex;
  position: absolute;
  padding-block: 1rem 1.5rem;
  inset: auto 0 0;
}

.text-column__resize--less {
  display: none;
}

.text-column--is-expanded .text-column__resize--more {
  display: none;
}

.text-column--is-expanded .text-column__resize--less {
  position: relative;
  display: flex;
  padding-left: 0;
  margin-bottom: -1rem;
}

