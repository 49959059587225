@import "./assets/scss/_config.scss";
@import "config";

.recent-reviews {
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem;
}

.recent-reviews__row,
.footer-kiyoh {
  position: relative;
  display: flex;
  flex-direction: column;
}

.footer-kiyoh {
  padding-bottom: 1.5rem;
}

.recent-reviews__header {
  position: relative;
  max-width: 100%;
}

.recent-reviews__header:before {
  position: absolute;
  inset: 50% 0;
  content: '';
  border-bottom: 1px solid var(--border-color-divider);
}

.recent-reviews__title {
  position: relative;
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  background-color: var(--white);
}

.recent-reviews__title span {
  font-weight: 700;
  color: var(--corp-color);
}

.recent-reviews__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 2.5rem 3.25rem;
  font-weight: 600;
}

.recent-reviews__logo {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  padding-block: .5rem;
  max-height: 6rem;
}

.recent-reviews__stars {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  padding-block: .5rem;
}

.recent-reviews__display {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recent-reviews__score__header {
  padding-block: 0;
  margin-block: 0;
  line-height: 1.5;
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--corp-color);
}

.recent-reviews__logo__star {
  fill: var(--corp-color);
}

.footer-kiyoh .recent-reviews__logo__star {
  fill: var(--text-color-lighten-30);
}

.footer-kiyoh .recent-reviews__score__header {
  color: var(--text-color-lighten-30);
}

.recent-reviews__score__text {
  padding-block: 0;
  margin-block: 0;
  font-size: .875rem;
  line-height: 1.125rem;
}

.recent-reviews__twc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: .5rem;
}

.recent-reviews__slider {
  width: 100%;
  margin-bottom: 1.5rem;
  padding-block: .5rem;
  cursor: grab;
}

.recent-reviews__slider .slick-prev,
.recent-reviews__slider .slick-next {
  top: 50%;
}

.recent-reviews-item {
  padding-inline: 0;
}

.recent-reviews-item:any-link {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.recent-reviews-item__inner {
  padding: 1.25rem 1.5rem .5rem;
  border: 1px solid var(--light-grey-22);
  border-radius: 1rem;
}

.recent-reviews-item__top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: .75rem;
}

.recent-reviews-item__star {
  width: 1.5rem;
  margin-right: .125rem;
  fill: var(--star-color);
}

.recent-reviews-item__score {
  padding-top: .375rem;
  color: var(--corp-color);
  font-weight: 600;
  font-size: 1.25rem;
}

.recent-reviews-item .recent-reviews-item__text {
  height: 6.125rem;
  text-align: center;
  line-height: 1.33;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 5;
  -webkit-line-clamp: 5; // firefox needs this -webkit prefix as well, usage 97%
  -webkit-box-orient: vertical;
  font-size: 0.9375rem;
  font-weight: 600;
}

.recent-reviews-item .recent-reviews-item__name {
  padding-inline: 1rem;
  text-align: center;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.125rem; 
}

.recent-reviews-item__name--date {
  color: var(--text-placeholder);
}

@media (min-width: 680px) {
  .recent-reviews__slider.slick-slider {
    padding-inline: 1rem;
  }

  .recent-reviews__slider .slick-prev {
    left: -1.5rem;
  }

  .recent-reviews__slider .slick-next {
    right: -1.5rem;
  }
  
  .recent-reviews-item {
    padding-inline: .75rem;
  }
}

@media (min-width: 62rem) {
  .footer-kiyoh {
    padding-bottom: 3rem;
  }
}

@media (min-width: 75rem) {
  .recent-reviews__row,
  .footer-kiyoh {
    flex-direction: row;
  }

  .footer-kiyoh {
    justify-content: center;
    padding-bottom: 0;
  }

  .recent-reviews__info {
    flex-direction: row;
  }

  .recent-reviews__logo {
    display: flex;
    justify-content: flex-end;
    flex-basis: 18%;
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid var(--border-color);
  }

  .recent-reviews__stars {
    justify-content: flex-end;
    flex-basis: 25%;
    padding-block: 0;
    padding-right: 1.5rem;
    border-right: 1px solid var(--border-color);
  }

  .recent-reviews__display {
    justify-content: center;
    align-items: flex-start;
    padding-left: 1.5rem;
  }

  .recent-reviews__score__header {
    line-height: 1em;
  }

  .recent-reviews__twc {
    flex-basis: 25%;
    justify-content: flex-start;
    margin-left: 1.125rem;
    padding-left: 1.5rem;
    border-left: 1px solid var(--border-color);
  }

  .recent-reviews-item {
    padding-inline: .75rem;
  }

  .recent-reviews-item__star {
    width: 1.75rem;
    margin-right: .5rem;
  }
}

